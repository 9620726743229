import { Vue, Component } from "vue-property-decorator";
import APP_UTILITIES from "@/utilities/commonFunctions";
import { LDContext } from "launchdarkly-js-client-sdk";
import APP_CONST from "@/constants/AppConst";

@Component
export default class LaunchDarklyComponent extends Vue {
  public demoFlagKey: typeof APP_CONST.FEATURE_KEYS[keyof typeof APP_CONST.FEATURE_KEYS] =
    APP_CONST.FEATURE_KEYS.playground;
  public currentContext: LDContext = {};
  public allFlags = [];
  public flagValue = false;

  public async getFlagValue(
    flagKey: typeof APP_CONST.FEATURE_KEYS[keyof typeof APP_CONST.FEATURE_KEYS]
  ): Promise<boolean> {
    return APP_UTILITIES.getFeatureFlag(flagKey);
  }
  public async getAllFlags(): Promise<any> {
    return APP_UTILITIES.getAllFeatureFlags();
  }
  public async getCurrentContext(): Promise<LDContext> {
    return APP_UTILITIES.getLDContext();
  }
  public async setContext(context: LDContext): Promise<void> {
    APP_UTILITIES.setLDContext(context);
  }
  public waitForClient(): Promise<void> {
    return APP_UTILITIES.waitForLDClient();
  }

  async mounted() {
    try {
      await this.waitForClient();
      this.currentContext = await this.getCurrentContext();
      this.allFlags = await this.getAllFlags();
      this.flagValue = await this.getFlagValue(this.demoFlagKey);
    } catch (error) {
      console.error("Error fetching feature flag values.", error);
    }
  }
}
